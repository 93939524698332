import './main.css'


function initBackgroundOnHover() {
  const backgroundOnHoverEls = document.querySelectorAll('.js-background-on-hover')

  backgroundOnHoverEls.forEach(el => {
    el.addEventListener('mouseenter', () => {
      const backgroundImageUrl = el.dataset.backgroundImage
      invariant(backgroundImageUrl, 'should have a bg image')
      document.body.style.backgroundImage = `url('${backgroundImageUrl}')`
    })

    el.addEventListener('mouseleave', () => {
      document.body.style.backgroundImage = ''
    })
  })
}

initBackgroundOnHover()


function invariant(condition, errorMessage) {
  if (!condition) {
    throw new Error(errorMessage)
  }
}
